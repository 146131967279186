import React from "react";
import { makeStyles, useTheme, createStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import { useGuild } from "../../contexts/GuildContext";
import RedeemIcon from "@material-ui/icons/Redeem";
// import { mainListItems, secondaryListItems } from "./listitem";
import MainData from "./Data/MainData";
import { useAuth } from "../../contexts/AuthContext";
import SideBar from "./SideBar";
import CreateCoupon from "./Coupons/CreateCoupon";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
// icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { Helmet } from "react-helmet";
import AddGiveaway from "./Giveaway/AddGiveaway";
import Admin from "./Admin/Admin";

import Logo from "./Logo";
import { useLocalStorage } from "../../utils/useLocalStorage";

import Payments from "./Payments/Payments";

import { useColorTheme } from "../../contexts/ColorThemeContext";
import Brightness2Icon from "@material-ui/icons/Brightness2";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import Tooltip from "@material-ui/core/Tooltip";
import Webhook from "./Webhook/Webhook";
import SendIcon from "@material-ui/icons/Send";

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    toolbar2: {
      marginBottom: theme.spacing(1),
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(1),
    },
    seperator: {
      flexGrow: 1,
    },
    seperator2: {
      flexGrow: 0.02,
    },
  })
);

const sidebar_list = [
  {
    index: 0,
    name: "Dashboard",
    admin: false,
    icon: <DashboardIcon />,
  },
  {
    index: 1,
    name: "Coupons",
    admin: false,
    icon: <ConfirmationNumberIcon />,
  },
  {
    index: 2,
    name: "Giveaway",
    admin: false,
    icon: <RedeemIcon />,
  },
  {
    index: 3,
    name: "Admin",
    admin: true,
    icon: <VerifiedUserIcon />,
  },
  {
    index: 4,
    name: "Payments",
    admin: false,
    icon: <AttachMoneyIcon />,
  },
  {
    index: 5,
    name: "Webhook",
    admin: true,
    icon: <SendIcon />,
  },
];

function getCurrSelected(index: number, token: string | null) {
  if (index === 0) return <MainData />;
  if (index === 1) return <CreateCoupon />;
  if (index === 2) return <AddGiveaway />;
  if (index === 3 && token) return <Admin />;
  if (index === 4) return <Payments />;
  if (index === 5) return <Webhook />;
  return <h1>Coupon</h1>;
}

export default function Dashboard() {
  const { colorTheme, setColorTheme } = useColorTheme();
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useTheme();
  const { logout, customClaim } = useAuth();
  const [selected, setSelected] = useLocalStorage(
    "torchlabs-dashboard-select",
    0
  );
  const { guilds, setCurrentGuild, currentGuild } = useGuild();

  // menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    // console.log(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGuildClick = (id: number) => {
    setCurrentGuild(id);
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Logo />
      <div className={classes.toolbar2} />
      <List>
        {sidebar_list
          .filter((s) => (customClaim === "admin" ? true : s.admin === false))
          .map(({ index, name, icon }) => (
            <SideBar
              key={index}
              icon={icon}
              name={name}
              handleSidebarClick={() => setSelected(index)}
              selected={selected === index}
            />
          ))}
      </List>
      {/* <Divider /> */}
    </div>
  );

  return (
    <div className={classes.root}>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>TorchLabs &bull; Dashboard</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
      </div>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} elevation={0}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <h2
            style={{
              textShadow:
                colorTheme === "dark" ? "2px 2px 5px #000000" : undefined,
              color: colorTheme === "dark" ? undefined : "#d43322",
            }}
          >
            <span style={{ fontWeight: 800 }}>Torch</span>{" "}
            <span style={{ fontWeight: 400 }}>Labs</span>
          </h2>
          <div className={classes.seperator} />
          <Button
            // className={classes.button}
            color="primary"
            variant="contained"
            onClick={() => logout()}
          >
            <Typography>Logout</Typography>
          </Button>
          <div className={classes.seperator2} />
          <Tooltip
            title={`Switch to ${
              colorTheme === "dark" ? "light" : "dark"
            } theme 🔥`}
          >
            <IconButton
              aria-label="delete"
              onClick={() =>
                setColorTheme(colorTheme === "dark" ? "light" : "dark")
              }
            >
              {colorTheme === "dark" ? (
                <WbSunnyIcon fontSize="inherit" />
              ) : (
                <Brightness2Icon fontSize="inherit" />
              )}
            </IconButton>
          </Tooltip>

          <div className={classes.seperator2} />
          {guilds && guilds?.length > 1 ? (
            <div>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                variant="outlined"
              >
                {guilds[currentGuild].server_name}
              </Button>

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {guilds.map(({ id, server_name }, index) =>
                  currentGuild !== index ? (
                    <MenuItem onClick={() => handleGuildClick(index)}>
                      {server_name}
                    </MenuItem>
                  ) : (
                    ""
                  )
                )}
              </Menu>
            </div>
          ) : (
            ""
          )}
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {getCurrSelected(selected, customClaim)}
      </main>
    </div>
  );
}
