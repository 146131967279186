import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import { TextField } from "formik-material-ui";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RegisterUserValidation } from "../../utils/validSchemas";
import { register_errors, RegCodes } from "../../utils/errorCodes";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paperBack: {
    borderRadius: 15,
    padding: "0px 10px 10px 10px",
    marginTop: 0,
  },
}));

const Register: React.FC = () => {
  const { signup } = useAuth();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [error, setError] = useState("");

  const handleRegister = async (password: string, email: string) => {
    // console.log(password, email);
    try {
      setError("");
      setLoading(true);
      await signup(password, email);
      history.push("/dashboard");
    } catch (error) {
      // console.log(error.code);
      console.log(error.code);
      setLoading(false);
      const code: keyof RegCodes = error.code;
      setError(register_errors[code]);
    }
    setLoading(false);
  };

  return (
    <section className="login-bg">
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>TorchLabs &bull; Register</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
      </div>
      <Container component="main" maxWidth="xs" className={classes.paperBack}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <Formik
              initialValues={{
                email: "",
                password: "",
                confirm_password: "",
              }}
              validationSchema={RegisterUserValidation}
              onSubmit={(data) => handleRegister(data.password, data.email)}
            >
              {({ submitForm, errors, values }) => (
                <Form className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item md={12} sm={6}>
                      <Field
                        component={TextField}
                        variant="outlined"
                        fullWidth
                        name="email"
                        type="email"
                        label="Email"
                      />
                    </Grid>
                    <Grid item md={12} sm={6}>
                      <Field
                        component={TextField}
                        variant="outlined"
                        fullWidth
                        name="password"
                        type="password"
                        label="Password"
                      />
                    </Grid>
                    <Grid item md={12} sm={6}>
                      <Field
                        component={TextField}
                        variant="outlined"
                        fullWidth
                        name="confirm_password"
                        type="password"
                        label="Confirm Password"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {error === "" ? (
                        ""
                      ) : (
                        <Alert icon={false} severity="error">
                          {error}
                        </Alert>
                      )}
                    </Grid>

                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    <Grid item md={12} sm={6}>
                      <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    className={classes.submit}
                  >
                    {loading ? (
                      <CircularProgress
                        style={{ height: 20, width: 20, color: "white" }}
                      />
                    ) : (
                      "Register"
                    )}
                  </Button>
                </Form>
              )}
            </Formik>
            <Grid container justify="flex-end">
              <Grid item>
                <Link href="/login" variant="body1">
                  Already have an account? <b>Sign In</b>
                </Link>
              </Grid>
            </Grid>
          </div>
        </Container>
      </Container>
    </section>
  );
};

export default Register;
