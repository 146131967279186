import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useEffect, useState } from "react";
import { functions } from "../../../../base";
import { usePayment } from "../../../../contexts/PaymentContext";

const AccountComplete = () => {
  const [stripeLogin, setStripeLogin] = useState("");
  const { loading, setLoading, accountStatus } = usePayment();

  if (accountStatus.setup_type === "bank") return <h1>Saved bank details</h1>;

  return (
    <div>
      <Typography>Account setup complete</Typography>
      <Button
        onClick={() => {
          setLoading(true);
          var refresh = functions.httpsCallable("getStripeLoginLink");
          refresh().then((val) => {
            const { url } = val.data;
            window.location.assign(url);
            setLoading(false);
          });
        }}
        disabled={loading}
      >
        {loading ? (
          <CircularProgress style={{ height: 20, width: 20 }} />
        ) : (
          "Log into Stripe"
        )}
      </Button>
    </div>
  );
};

export default AccountComplete;
