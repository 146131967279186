import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";
import "./index.css";
import { ColorThemeProvider } from "./contexts/ColorThemeContext";
// const theme = createMuiTheme({
//   palette: {
//     type: "dark",
//     primary: {
//       main: "#d43322",
//     },
//     background: {
//       default: "#262c49",
//       paper: "#10163a",
//       // default: "#D3DBD9",
//       // paper: "#CED8D6",
//     },
//     // Used by `getContrastText()` to maximize the contrast between
//     // the background and the text.
//     contrastThreshold: 3,
//     // Used by the functions below to shift a color's luminance by approximately
//     // two indexes within its tonal palette.
//     // E.g., shift from Red 500 to Red 300 or Red 700.
//     tonalOffset: 0.2,
//   },

//   typography: {
//     fontFamily: "'MADE TOMMY', sans-serif",
//     // h1: { color: "white" },
//     // h2: { color: "white" },
//     // h3: { color: "white" },
//     // h4: { color: "white" },
//     // h6: { color: "white" },
//     // h7: { color: "white" },
//     // subtitle1: { color: "white" },
//     // subtitle2: { color: "white" },
//     // body1: { color: "white" },
//     // body2: { color: "white" },
//   },
// });

ReactDOM.render(
  // <ThemeProvider theme={theme}>
  <React.StrictMode>
    <ColorThemeProvider>
      <App />
    </ColorThemeProvider>
  </React.StrictMode>,
  // </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
