import React, { useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import WorkIcon from "@material-ui/icons/Work";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import { useQuery } from "react-query";
import { useGuild } from "../../../contexts/GuildContext";
import { store, functions } from "../../../base";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import GetAppIcon from "@material-ui/icons/GetApp";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { getMonthAndDay } from "../../../utils/formatDate";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    paymentText: {
      textShadow:
        theme.palette.type === "dark" ? "2px 2px 5px #000000" : undefined,
    },
    pink: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.contrastText,
    },
  })
);

function getURL(url: string[]) {
  return window.location.hostname === "localhost"
    ? url[0].replace("https://storage.googleapis.com", "http://localhost:9199")
    : url[0];
}

interface Invoice {
  doc_id: string;
  encryption_key: string;
  file_name: string;
  file_path: string;
  id: string;
  invoice_period: {
    start: number;
    end: number;
  };
  timestamp: number;
}

async function getCoupons(current_guild: string) {
  let d = new Date();
  let all_coupons: Invoice[] = [];
  const couponRef = store
    .collection("channels")
    .doc(current_guild)
    .collection("invoices");
  const couponData = await couponRef.get();
  couponData.docs.forEach((doc) => {
    all_coupons.push({ ...doc.data(), doc_id: doc.id } as Invoice);
  });
  return all_coupons;
}

export default function Invoices() {
  const classes = useStyles();
  const { guilds, currentGuild } = useGuild();
  const [loading, setLoading] = useState(false);
  const [currClicked, setCurrClicked] = useState<null | string>(null);

  // @ts-ignore
  const invoice_list = useQuery<Coupon[], Error>(["invoice_list"], () =>
    getCoupons(guilds![currentGuild]?.id)
  );

  function generate(inv: Invoice) {
    const start = new Date(inv.invoice_period.start);
    const end = new Date(inv.invoice_period.end);
    const now = new Date();
    return (
      <ListItem key={inv.id}>
        <ListItemAvatar>
          <Avatar className={classes.pink}>
            <PictureAsPdfIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography component={"span"}>
              {now.getMonth() === start.getMonth() + 1 ? (
                <div style={{ padding: 5 }}>
                  {`${getMonthAndDay(start)} - ${getMonthAndDay(end)}`}
                  &nbsp;&nbsp;&nbsp;
                  <Chip size="small" color="primary" label="latest" />
                </div>
              ) : (
                <div style={{ padding: 2 }}>{`${getMonthAndDay(
                  start
                )} - ${getMonthAndDay(end)}`}</div>
              )}
            </Typography>
          }
          secondary={inv.id}
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="delete"
            disabled={loading}
            onClick={() => {
              setLoading(true);
              setCurrClicked(inv.id);
              var getInvoice = functions.httpsCallable("getInvoice");
              getInvoice({
                guild_id: guilds![currentGuild].id,
                month: inv.doc_id,
              })
                .then((val) => {
                  const { url } = val.data;
                  window.open(getURL(url), "_blank");
                  setLoading(false);
                })
                .catch((err) => {
                  // console.log(err);
                  setLoading(false);
                });
            }}
          >
            {loading && currClicked === inv.id ? (
              <CircularProgress style={{ height: 20, width: 20 }} />
            ) : (
              <GetAppIcon />
            )}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  // console.log(invoice_list.data);
  return (
    <div>
      <Typography variant="h5" className={classes.paymentText}>
        Invoices
      </Typography>
      <br />
      <List dense>
        {invoice_list.data
          ?.sort((a: Invoice, b: Invoice) => {
            // console.log(a, b);
            return a.invoice_period.start < b.invoice_period.start ? 1 : -1;
          })
          .map((inv) => generate(inv))}
      </List>
    </div>
  );
}
