import React, { useState } from "react";
import { TextField, Select } from "formik-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { store } from "../../../base";
import {
  AdminGiveawaySearch,
  AdminGiveawaySubmit,
} from "../../../utils/validSchemas";
import { useGuild } from "../../../contexts/GuildContext";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { Formik, Field, Form } from "formik";
import { useMutation } from "react-query";
import { functions } from "../../../base";
import Alert from "../../../common/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "transparent",
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
  },
  paper2: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  formControl: {
    marginTop: theme.spacing(0),
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
  },
  dialogText: {
    borderRadius: 5,
    border: "1px solid gray",
    padding: 5,
  },
}));

async function searchByDiscoTag({
  tag,
  guildId,
}: {
  tag: string;
  guildId: string;
}) {
  const query = store
    .collection("channels")
    .doc(guildId)
    .collection("users")
    .where("tag", "==", tag);
  const result = await query.get();
  // console.log(result.docs[0]);
  if (!result.docs[0]) throw new Error("User not found");
  const user = result.docs[0];
  console.log(user.data());
  if (!user.data().email)
    throw new Error("Please ask this user to enter their email to the bot");
  return { ...user.data(), uid: user.id };
}

interface AdminGiveaway {
  guildId: string;
  giveaway_amount: number;
  uid: string;
  plan_name: string;
}

async function setGiveaway(data: AdminGiveaway) {
  var addGiveaway = functions.httpsCallable("addGiveawayDataAdmin");

  let res = await addGiveaway(data);

  const ret: any = res.data;

  console.log(ret);

  return ret;
}

const Giveaway = () => {
  const { guilds, currentGuild } = useGuild();
  const classes = useStyles();
  const [error, setError] = useState("");
  const [searchResult, setSearchResult] = useState<any>();
  const [open, setOpen] = React.useState(false);
  const [sendGuildId, setSendGuildId] = useState("");

  //@ts-ignore
  const mutation = useMutation<any, Error>("set-giveaway", setGiveaway, {
    onError: (error) => {
      setError(error.message);
      // console.log(JSON.stringify(error));
    },
  });
  //@ts-ignore
  const tag_mutation = useMutation<any, Error>("search-tag", searchByDiscoTag, {
    onError: (error) => {
      setError(error.message);
      // console.log(JSON.stringify(error));
    },
    onSuccess: (data) => {
      setOpen(true);
      setSearchResult(data);
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  if (!guilds![currentGuild]) return <h1>Loading...</h1>;

  console.log(guilds![currentGuild], currentGuild);
  return (
    <div>
      <Alert
        isOpen={tag_mutation.isError || mutation.isError}
        message={error}
        type={"error"}
      />
      <Alert isOpen={mutation.isError} message={error} type={"error"} />
      <Alert
        isOpen={mutation!.isSuccess}
        message={`Successfully send data to ${
          mutation!.isSuccess ? searchResult.tag : ""
        }!`}
        type={"success"}
      />
      <Formik
        initialValues={{
          search_tag: "",
          guild_id: guilds![currentGuild].id,
        }}
        validationSchema={AdminGiveawaySearch}
        onSubmit={async (values) => {
          await tag_mutation.mutate({
            tag: values.search_tag,
            guildId: values.guild_id,
          });
          setSendGuildId(values.guild_id);
        }}
      >
        {({ submitForm, errors, values }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item md={5}>
                <Field
                  component={TextField}
                  variant="filled"
                  fullWidth
                  name="search_tag"
                  type="text"
                  label="Discord Tag"
                />
              </Grid>
              <Grid item md={5}>
                <Field
                  component={TextField}
                  variant="filled"
                  fullWidth
                  name="guild_id"
                  placeholder={guilds![currentGuild].id}
                  type="text"
                  label="Guild Id (Default is current guild)"
                />
              </Grid>
              <Grid item md={1}>
                <IconButton
                  onClick={submitForm}
                  disabled={tag_mutation.isLoading}
                >
                  {tag_mutation.isLoading ? (
                    <CircularProgress style={{ height: 20, width: 20 }} />
                  ) : (
                    <SearchIcon />
                  )}
                </IconButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Send Data to <b>{searchResult ? searchResult.tag : ""}</b>
        </DialogTitle>

        <Formik
          initialValues={{
            giveaway_amount: 1,
            plan_name: "Basic",
          }}
          validationSchema={AdminGiveawaySubmit}
          onSubmit={async ({ giveaway_amount, plan_name }) => {
            await mutation.mutate({
              guildId: sendGuildId,
              giveaway_amount,
              uid: searchResult.uid,
              plan_name,
            });
          }}
        >
          {({ submitForm, errors, values }) => (
            <Form>
              <Grid container spacing={3}>
                <DialogContent>
                  <Grid item md={12}>
                    <FormControl
                      variant="filled"
                      className={classes.formControl}
                      fullWidth
                    >
                      <InputLabel id="demo-simple-select-filled-label">
                        Plan Name
                      </InputLabel>
                      <Field
                        component={Select}
                        name={"plan_name"}
                        inputProps={{
                          id: "type-simple",
                        }}
                      >
                        <MenuItem value={"Basic"}>Basic</MenuItem>
                        <MenuItem value={"Premium"}>Premium</MenuItem>
                        <MenuItem value={"Elite"}>Elite</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                  <br />
                  <Grid item md={12}>
                    <Field
                      component={TextField}
                      variant="filled"
                      fullWidth
                      name="giveaway_amount"
                      type="number"
                      label="Amount"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">GB</InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </DialogContent>
                <Grid item md={12}>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Cancel
                    </Button>
                    <Button
                      onClick={submitForm}
                      disabled={mutation.isLoading}
                      color="primary"
                      autoFocus
                    >
                      {mutation.isLoading ? (
                        <CircularProgress style={{ height: 20, width: 20 }} />
                      ) : (
                        "Send"
                      )}
                    </Button>
                  </DialogActions>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default Giveaway;
