import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import { CouponAddNonLimitedValidation } from "../../../utils/validSchemas";
import Grid from "@material-ui/core/Grid";
import { useGuild } from "../../../contexts/GuildContext";
import { TextField, Select } from "formik-material-ui";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";
import {
  FilledDatePicker,
  PromoAddData,
  PromoAPIData,
  PromoData,
} from "./types";
import { useMutation } from "react-query";
import { functions } from "../../../base";
import DateFnsUtils from "@date-io/date-fns";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { fieldToDatePicker } from "formik-material-ui-pickers";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Alert from "../../../common/Alert";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

function MuiDatePicker(props: FilledDatePicker) {
  return (
    <KeyboardDatePicker {...fieldToDatePicker(props)} inputVariant="filled" />
  );
}

async function setPromo(promo: PromoAddData) {
  var addProm = functions.httpsCallable("addPromoCode");
  const new_promo: PromoAPIData = (
    await addProm({ ...promo, plan_type: "ISP" })
  ).data;
  // console.log(new_promo);
  const extracted = {
    id: new_promo.id,
    code: new_promo.code,
    active: new_promo.active,
    expires_at: new_promo.expires_at,
    times_redeemed: new_promo.times_redeemed,
    percent_off: new_promo.coupon.percent_off,
    type: new_promo.coupon.name,
    type_id: new_promo.coupon.id,
  };
  // console.log(extracted);
  return extracted;
}

const useStyles = makeStyles((theme) => ({
  couponText: {
    textShadow:
      theme.palette.type === "dark" ? "2px 2px 5px #000000" : undefined,
  },
}));

export const ISPCoupon = () => {
  const classes = useStyles();
  const { guilds, currentGuild } = useGuild();
  // @ts-ignore
  const mutation = useMutation<PromoData, Error>(setPromo, {
    onError: (error) => setError(error.message),
  });
  const [error, setError] = useState("");

  return (
    <div>
      <Alert isOpen={mutation!.isError} message={error} type={"error"} />
      <Alert
        isOpen={mutation!.isSuccess}
        message={"Successfully Added Coupon!"}
        type={"success"}
      />
      <Typography variant="h5" className={classes.couponText}>
        <b>ISP</b> Coupon
      </Typography>
      <br />
      <Formik
        initialValues={{
          type: "ISP5",
          code: "",
          expires_at: new Date(),
          percent_off: 5,
        }}
        validationSchema={CouponAddNonLimitedValidation}
        onSubmit={async (values) => {
          await mutation.mutate({
            guildId: guilds![currentGuild].id,
            ...values,
            expires_at: Math.floor(values.expires_at.getTime() / 1000),
          });
        }}
      >
        {({ submitForm, errors, values }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item md={4}>
                <Field
                  component={TextField}
                  variant="filled"
                  fullWidth
                  name="code"
                  type="text"
                  label="Coupon code"
                />
              </Grid>
              <Grid item md={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Field
                    component={MuiDatePicker}
                    fullWidth
                    name="expires_at"
                    label="Coupon Expiry"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={4}>
                <FormControl fullWidth variant="filled">
                  <InputLabel htmlFor="age-simple">{"Percentage"}</InputLabel>
                  <Field
                    component={Select}
                    name={"type"}
                    inputProps={{
                      id: "type-simple",
                    }}
                  >
                    <MenuItem value={"ISP5"}>5</MenuItem>
                  </Field>
                </FormControl>
              </Grid>
              {/* <Grid item>
                {window.location.hostname !== "localhost"
                  ? "Currently developing"
                  : ""}
              </Grid> */}
              <Grid item md={12}>
                <DialogActions>
                  <Button
                    onClick={submitForm}
                    variant="outlined"
                    disabled={mutation.isLoading}
                  >
                    {mutation.isLoading ? (
                      <CircularProgress style={{ height: 20, width: 20 }} />
                    ) : (
                      "Create Coupon"
                    )}
                  </Button>
                </DialogActions>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ISPCoupon;
