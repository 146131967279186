import * as yup from "yup";

export const CouponAddNonLimitedValidation = yup.object({
  code: yup.string().required("code is required"),
  expires_at: yup.date().required("date is required"),
});

export const RegisterUserValidation = yup.object({
  email: yup.string().email().required("email is required"),
  password: yup
    .string()
    .required("password is required")
    .min(6, "password must be at least 6 characters long")
    .max(30, "password must be less than 30 characters long")
    .matches(/^(?=.*\d).{6,15}$/, "password must include at least 1 number"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "passwords must match"),
});

export const AdminGiveawaySearch = yup.object({
  search_tag: yup.string().required("search tag is required"),
  guild_id: yup.string().required("search tag is required"),
});

export const AdminGiveawaySubmit = yup.object({
  plan_name: yup.string().required("plan name is required"),
  giveaway_amount: yup
    .number()
    .required("amount tag is required")
    .min(0.1, "minium amount is 0.1GB")
    .max(10, "maximum amount is 10GB"),
});

export const BankDetailsValidation = yup.object({
  legal_name: yup
    .string()
    .required("amount tag is required")
    .min(0.1, "minium amount is 0.1GB")
    .max(10, "maximum amount is 10GB"),
  routing_number: yup
    .number()
    .required("routing number is required")
    .min(9, "routing number is 9 digits long")
    .max(9, "routing number is 9 digits long"),
  account_number: yup.number().required("account number is required"),
});

// account_type: "Buisness Checking",
// legal_name: "",
// routing_number: "",
// account_number: "",
