import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import "./fire.css";

interface Props {
  className?: any;
}
const Logo: React.FC<Props> = ({ className, ...props }) => {
  const classes = classNames("brand", className);

  return (
    <div {...props} className={classes} style={{ marginLeft: 90 }}>
      <h1 className="m-0">
        <Link to="/">
          <div className="fire">
            <div className="fire-left">
              <div className="main-fire"></div>
              <div className="particle-fire"></div>
            </div>
            <div className="fire-main">
              <div className="main-fire"></div>
              <div className="particle-fire"></div>
            </div>
            <div className="fire-right">
              <div className="main-fire"></div>
              <div className="particle-fire"></div>
            </div>
            <div className="fire-bottom">
              <div className="main-fire"></div>
            </div>
          </div>
        </Link>
      </h1>
    </div>
  );
};

export default Logo;
