import React, { useContext, useState, useEffect } from "react";
// import Firebase from "firebase";
import { auth, functions } from "../base";
import { RegisterError } from "../utils/errorCodes";
interface Context {
  currentUser: firebase.default.User | null;
  login: (
    email: string,
    password: string
  ) => Promise<firebase.default.auth.UserCredential>;
  signup: (password: string, email: string) => void;
  logout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  updateEmail: (email: string) => Promise<void>;
  updatePassword: (password: string) => Promise<void>;
  customClaim: string | null;
  loading: boolean;
}

const AuthContext = React.createContext({} as Context);

export function useAuth() {
  return useContext(AuthContext);
}

interface Props {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<firebase.default.User | null>(
    {} as firebase.default.User
  );
  const [loading, setLoading] = useState(true);
  const [customClaim, setCustomClaim] = useState<string | null>(null);

  async function signup(password: string, email: string) {
    const checkEmailWithGuilds = functions.httpsCallable(
      "checkEmailWithGuilds"
    );
    const check = (await checkEmailWithGuilds({ email })).data;
    console.log(check);
    if (!check) {
      console.log("here");
      throw new RegisterError("auth/invalid_guild");
    }
    const usr = await auth.createUserWithEmailAndPassword(email, password);
    usr.user?.sendEmailVerification();
    return usr;
  }

  async function login(email: string, password: string) {
    return await auth.signInWithEmailAndPassword(email, password);
  }

  async function logout() {
    return await auth.signOut();
  }

  async function resetPassword(email: string) {
    return await auth.sendPasswordResetEmail(email);
  }

  async function updateEmail(email: string) {
    return await currentUser!.updateEmail!(email);
  }

  async function updatePassword(password: string) {
    return await currentUser!.updatePassword!(password);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      if (user) {
        const claim: any = await user!.getIdTokenResult();
        setCustomClaim(claim.claims.role ? claim.claims.role : null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value: Context = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    customClaim,
    loading,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
