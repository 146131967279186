import React from "react";
import Typography from "@material-ui/core/Typography";

interface Props {
  children: React.ReactNode;
}

const Title: React.FC<Props> = ({ children }) => {
  return (
    <Typography component="h2" variant="h6" gutterBottom>
      {children}
    </Typography>
  );
};
export default Title;
