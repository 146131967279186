import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { store, db } from "../../../base";
import { useGuild } from "../../../contexts/GuildContext";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { useMutation } from "react-query";
import { functions } from "../../../base";
import Alert from "../../../common/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { v4 as uuidv4 } from "uuid";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  paper: {
    // backgroundColor: "transparent",
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  formControl: {
    marginTop: theme.spacing(0),
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
  },
  dialogText: {
    borderRadius: 5,
    border: "1px solid gray",
    padding: 5,
  },
}));

function getPlanName(plan_name: string) {
  const deduc = [
    {
      plan: "Basic",
      plan_name: "Basic GW",
    },
    {
      plan: "Premium",
      plan_name: "Premium GW",
    },
    {
      plan: "Elite",
      plan_name: "Elite GW",
    },
  ];
  let pln = deduc.find((x) => x.plan === plan_name);
  return pln!.plan_name;
}

interface Giveaway {
  guildId: string;
  giveaway_amount: number;
  uid: string;
  plan_name: string;
  email: string;
}

async function genCheckoutLink(
  bandwidth: number,
  channel: string,
  uid: string,
  plan_name: string
) {
  const pln = getPlanName(plan_name);
  const host =
    window.location.hostname === "localhost"
      ? "http://localhost:3001"
      : "https://adoring-lamarr-bbdd47.netlify.app";
  const data = await db.ref("/giveaway_products").get();
  for (const [, value] of Object.entries(data.val())) {
    let price;
    //@ts-ignore
    if (value.name === pln) {
      //@ts-ignore
      price = value.prices.find((price: any) => bandwidth === price.bandwidth);
    }
    //@ts-ignore
    if (price) {
      const checkoutId = db.ref(`checkout_tokens_giveaway/${uid}`);
      const checkout_key = uuidv4();
      await checkoutId.set({ key: checkout_key });
      return `${host}/checkout_giveaway/${checkout_key}/${price.id}/${channel}/${uid}`;
    }
  }
  return "";
}

function getPerGbPrice(plan_name: string) {
  const deduc = [
    {
      plan: "Basic",
      deduction: 5,
    },
    {
      plan: "Premium",
      deduction: 8,
    },
    {
      plan: "Elite",
      deduction: 14,
    },
  ];
  let pln = deduc.find((x) => x.plan === plan_name);
  return pln!.deduction;
}

function getPriceDeduction(plan_name: string, amount: number) {
  const deduc = [
    {
      plan: "Basic",
      deduction: 5,
    },
    {
      plan: "Premium",
      deduction: 8,
    },
    {
      plan: "Elite",
      deduction: 14,
    },
  ];
  let pln = deduc.find((x) => x.plan === plan_name);
  return pln!.deduction * amount;
}

async function searchByDiscoTag({
  tag,
  guildId,
}: {
  tag: string;
  guildId: string;
}) {
  const query = store
    .collection("channels")
    .doc(guildId)
    .collection("users")
    .where("tag", "==", tag);
  const result = await query.get();
  // console.log(result.docs[0]);
  if (!result.docs[0]) throw new Error("User not found");
  const user = result.docs[0];
  console.log(user.data());
  if (!user.data().email)
    throw new Error("Please ask this user to enter their email to the bot");
  return { ...user.data(), uid: user.id };
}

async function setGiveaway({
  data,
  checked,
}: {
  data: Giveaway;
  checked: boolean;
}) {
  if (!checked) throw new Error("Please confirm by checking the box");

  var addGiveaway = functions.httpsCallable("addGiveawayData");

  let res = await addGiveaway(data);

  const ret: any = res.data;

  console.log(ret);

  return ret;
}

const AddGiveaway = () => {
  const { guilds, currentGuild } = useGuild();
  const classes = useStyles();
  const [searchTag, setSearchTag] = useState("");
  const [error, setError] = useState("");
  const [searchResult, setSearchResult] = useState<any>();
  const [open, setOpen] = React.useState(false);
  const [age, setAge] = React.useState("Basic");
  const [val, setValue] = React.useState(1);
  const [checked, setChecked] = React.useState(false);
  const [no_balance, setNoBalance] = useState(false);
  const [checkoutLink, setCheckoutLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  //@ts-ignore
  const mutation = useMutation<any, Error>("set-giveaway", setGiveaway, {
    onError: (error) => {
      setError(error.message);
      // console.log(JSON.stringify(error));
    },
    onSuccess: async (data) => {
      if (data.type === "error") {
        if (data.error_type === "no-account-balance") {
          const link = await genCheckoutLink(
            val,
            guilds![currentGuild].id,
            searchResult.uid,
            age
          );
          setCheckoutLink(link);
          setNoBalance(true);
        }
      }
    },
  });
  //@ts-ignore
  const tag_mutation = useMutation<any, Error>("search-tag", searchByDiscoTag, {
    onError: (error) => {
      setError(error.message);
      // console.log(JSON.stringify(error));
    },
    onSuccess: (data) => {
      setOpen(true);
      setSearchResult(data);
    },
  });

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAge(event.target.value as string);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendData = async (data: Giveaway, checked: boolean) => {
    mutation.mutate({ data, checked });
  };

  // console.log(guilds![currentGuild], currentGuild);
  return (
    <div>
      <Alert
        isOpen={tag_mutation.isError || mutation.isError}
        message={error}
        type={"error"}
      />
      <Alert isOpen={mutation.isError} message={error} type={"error"} />
      <Alert
        isOpen={mutation!.isSuccess && !no_balance}
        message={`Successfully send data to ${
          mutation!.isSuccess && !no_balance ? searchResult.tag : ""
        }!`}
        type={"success"}
      />
      <Paper className={classes.paper}>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await tag_mutation.mutate({
              tag: searchTag,
              guildId: guilds![currentGuild].id,
            });
          }}
        >
          <TextField
            id="filled-basic"
            label="Search Discord Tag"
            variant="outlined"
            fullWidth
            onChange={(e) => setSearchTag(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton disabled={tag_mutation.isLoading} type="submit">
                    {tag_mutation.isLoading ? (
                      <CircularProgress style={{ height: 20, width: 20 }} />
                    ) : (
                      <SearchIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </form>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Send Data to <b>{searchResult ? searchResult.tag : ""}</b>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item md={10}>
                <FormControl
                  variant="filled"
                  className={classes.formControl}
                  fullWidth
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Plan Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={age}
                    onChange={handleChange}
                  >
                    <MenuItem value={"Basic"}>Basic</MenuItem>
                    <MenuItem value={"Premium"}>Premium</MenuItem>
                    <MenuItem value={"Elite"}>Elite</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={2}>
                <Typography variant="h6">${getPerGbPrice(age)}/GB</Typography>
              </Grid>
              <Grid item md={12}>
                {/* <TextField
                  fullWidth
                  id="standard-number"
                  label="Amount in GB"
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={val}
                  onChange={(e) => setValue(parseInt(e.target.value))}
                  className={classes.formControl}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">GB</InputAdornment>
                    ),
                  }}
                /> */}
                <FormControl
                  variant="filled"
                  className={classes.formControl}
                  fullWidth
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Amount
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={val}
                    onChange={(e) => setValue(e.target.value as number)}
                  >
                    <MenuItem value={1}>1GB</MenuItem>
                    <MenuItem value={2}>2GB</MenuItem>
                    <MenuItem value={3}>3GB</MenuItem>
                    <MenuItem value={4}>4GB</MenuItem>
                    <MenuItem value={5}>5GB</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <Paper elevation={0} className={classes.dialogText}>
                  <Typography variant="h6">
                    ${getPriceDeduction(age, val)} will be deducted from your
                    end of month payment
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          color="primary"
                          checked={checked}
                          onChange={handleCheckChange}
                        />
                      }
                      label="Please check to confirm"
                      labelPlacement="end"
                    />
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            {no_balance ? (
              <Paper
                elevation={0}
                className={classes.dialogText}
                style={{ marginTop: 20 }}
              >
                <Typography variant="h6">
                  You do not have enough balance to send a giveaway on credit,
                  please use this{" "}
                  <Link href={checkoutLink} target="_blank">
                    link
                  </Link>{" "}
                  to checkout. After a successfull payment, the data will be
                  sent to <b>{searchResult.tag}</b>.
                </Typography>
                <br />
                <Typography variant="body1">
                  For security purposes checkout links are only valid once.
                  <Button
                    onClick={async () => {
                      setIsLoading(true);
                      const link = await genCheckoutLink(
                        val,
                        guilds![currentGuild].id,
                        searchResult.uid,
                        age
                      );
                      setCheckoutLink(link);
                      setIsLoading(false);
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress style={{ height: 20, width: 20 }} />
                    ) : (
                      "Click here to generate a new link"
                    )}
                  </Button>
                </Typography>
              </Paper>
            ) : (
              ""
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                console.log(searchResult);
                handleSendData(
                  {
                    guildId: guilds![currentGuild].id,
                    giveaway_amount: val,
                    uid: searchResult.uid,
                    email: searchResult.email,
                    plan_name: age,
                  },
                  checked
                );
              }}
              disabled={mutation.isLoading || no_balance}
              color="primary"
              autoFocus
            >
              {mutation.isLoading ? (
                <CircularProgress style={{ height: 20, width: 20 }} />
              ) : (
                "Send"
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </div>
  );
};

export default AddGiveaway;
