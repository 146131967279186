import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useQuery } from "react-query";
import Title from "../Title";
import { store } from "../../../base";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import { calcTransFees } from "@torchlabs/transactions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {
  GwPlans,
  SellingPlans,
  PaidStatus,
} from "@torchlabs/transactions/lib/types";

const useStyles = makeStyles((theme) => ({
  depositContext: {
    flex: 1,
  },
  dataText: {
    textShadow:
      theme.palette.type === "dark" ? "2px 2px 5px #d43322" : undefined,
  },
}));

type TodayOrders = firebase.default.firestore.QuerySnapshot<{
  paid: string;
  plan_name: GwPlans & SellingPlans;
  bandwidth: string;
  proxy_count: string;
  price: string;
  payment_status: PaidStatus;
}>;

async function getDailySales(guildId: string) {
  const today = new Date().setHours(0, 0, 0, 0);
  const todayEnd = new Date().setHours(23, 59, 59, 59);
  const transQuery = store
    .collection("channels")
    .doc(guildId)
    .collection("transactions");
  const transTodayQuery = transQuery
    .where("timestamp", ">=", today)
    .where("timestamp", "<=", todayEnd);
  const transToday = (await transTodayQuery.get()) as TodayOrders;
  let total_trans_paid = 0;
  transToday.docs.forEach((trans) => {
    const data = trans.data();
    if (
      data.plan_name === "Giveaway" ||
      (parseFloat(data.paid) < 1 && parseFloat(data.paid) !== 0)
    )
      return;
    total_trans_paid += calcTransFees(
      data.plan_name,
      data.paid,
      data.payment_status,
      data.price,
      data.bandwidth ? data.bandwidth : data.proxy_count
    );
  });
  return total_trans_paid;
}

const title = (
  <React.Fragment>
    <Typography color="inherit">
      Your sales income today after Stripe fees and cost of data
    </Typography>
    <List component="nav" aria-label="secondary mailbox folders">
      <ListItem>
        <ListItemText primary="Stripe Fees: 2.9% + 30 cents per transaction" />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={
            <Typography>
              <Typography>Data cost:</Typography>
              <Typography>Basic Residential 1 GB = 2$</Typography>
              <Typography>Premium Residential 1 GB = 5$ </Typography>
              <Typography>Elite Residential 1 GB = 11$</Typography>
              <Typography>ISP = 1.5$</Typography>
            </Typography>
          }
        />
      </ListItem>
    </List>
  </React.Fragment>
);

interface Props {
  guildId: string;
  selectedDate: any;
}
const DataUSDToday: React.FC<Props> = ({ guildId, selectedDate }) => {
  const classes = useStyles();
  const orders_today = useQuery<number | undefined, Error>(
    ["total_daily_sales", guildId],
    () => getDailySales(guildId)
  );
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <React.Fragment>
      <Grid container>
        <Grid item>
          <Title>Daily Sales Income</Title>
        </Grid>
        <Grid item>
          <Typography component="p" variant="h4" className={classes.dataText}>
            $
            {orders_today.data?.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
          </Typography>
          <Typography color="textSecondary" className={classes.depositContext}>
            {`on ${selectedDate.getDate()}, ${
              monthNames[selectedDate.getMonth()]
            }, ${selectedDate.getFullYear()}`}
          </Typography>
        </Grid>
        <Grid container item md={12} justify="flex-end">
          <Tooltip title={title} arrow>
            <InfoIcon />
          </Tooltip>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default DataUSDToday;
