import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import ResiCoupon from "./RestCoupon/ResiCoupon";
import ISPCoupon from "./ISPCoupon";
import CouponList from "./CouponList";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  grid: {
    width: "100%",
    margin: "0px",
  },
  productName: {
    margin: theme.spacing(1),
  },
  paper: {
    backgroundColor: "transparent",
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  paper2: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

const CreateCoupon = () => {
  const classes = useStyles();

  return (
    <div>
      <Paper className={classes.paper} elevation={0}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Paper className={classes.paper2}>
              <ResiCoupon />
            </Paper>
          </Grid>
          <Grid item md={12}>
            <Paper className={classes.paper2}>
              <ISPCoupon />
            </Paper>
          </Grid>
          <Grid item md={12}>
            <Paper className={classes.paper2}>
              <CouponList />
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default CreateCoupon;
