import React, { useState } from "react";
import { useQuery } from "react-query";
import { useGuild } from "../../../contexts/GuildContext";
import { store } from "../../../base";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { useMutation, useQueryClient } from "react-query";
import CircularProgress from "@material-ui/core/CircularProgress";

interface Coupon {
  code: string;
  expires_at: number;
  num_redemtions: number;
  created: number;
  coupon: { percent_off: number };
  max_redemptions: number;
  metadata: { plan_type: string };
  doc_id: string;
  coupon_type: string;
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  couponText: {
    textShadow:
      theme.palette.type === "dark" ? "2px 2px 5px #000000" : undefined,
  },
}));

function formatDate(timestamp: number) {
  const d = new Date(timestamp * 1000);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
}
async function getCoupons(current_guild: string) {
  let d = new Date();
  let all_coupons: Coupon[] = [];
  const couponRef = store
    .collection("channels")
    .doc(current_guild)
    .collection("coupons")
    .where("expires_at", ">", Math.floor(d.getTime() / 1000));
  const couponData = await couponRef.get();
  couponData.docs.forEach((doc) => {
    all_coupons.push({ ...doc.data(), doc_id: doc.id } as Coupon);
  });
  all_coupons.sort(function (a, b) {
    return b.created - a.created;
  });
  return all_coupons;
}

async function deleteCoupon({
  id,
  current_guild,
}: {
  id: string;
  current_guild: string;
}) {
  return await store
    .collection("channels")
    .doc(current_guild)
    .collection("coupons")
    .doc(id)
    .delete();
}

const CouponList = () => {
  const { guilds, currentGuild } = useGuild();
  // const [error, setError] = useState("");
  const queryClient = useQueryClient();

  // @ts-ignore
  const coupon_list = useQuery<Coupon[], Error>(["coupon_list"], () =>
    getCoupons(guilds![currentGuild]?.id)
  );
  // @ts-ignore
  const mutation = useMutation<string, Error>(deleteCoupon, {
    // onError: (error) => {
    //   setError(error.message);
    //   // console.log(JSON.stringify(error));
    // },
    onSuccess: (id) => {
      let new_lst = coupon_list.data?.filter((v) => v.doc_id === id);
      console.log(new_lst);
      queryClient.setQueryData(["coupon_list"], new_lst);
    },
  });
  const classes = useStyles();
  const [copyText, setCopyText] = useState("Click to copy");
  const [currClicked, setCurrClicked] = useState<string | null>(null);

  if (coupon_list.isLoading) return <h4>Loading...</h4>;
  return (
    <div>
      <Typography variant="h5" className={classes.couponText}>
        <b>Active</b> Coupons
      </Typography>
      <TableContainer component={Paper} elevation={0}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Plan Type</TableCell>
              <TableCell align="right">Expires / Max Redemtions</TableCell>
              <TableCell align="right">Coupon Type</TableCell>
              <TableCell align="right">Created</TableCell>
              <TableCell align="right">Percentage Off</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coupon_list.data!.map((row) => (
              <TableRow key={row.code}>
                <TableCell component="th" scope="row">
                  <Tooltip title={copyText} arrow>
                    <Chip
                      color="primary"
                      label={<Typography>{row.code}</Typography>}
                      clickable
                      onClick={() => {
                        setCopyText("Successfully copied to clipboard!");
                        navigator.clipboard.writeText(row.code);
                        setTimeout(() => {
                          setCopyText("Click to copy");
                        }, 2000);
                      }}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Typography>
                    {row.metadata.plan_type
                      ? row.metadata.plan_type
                      : "Will show on the next created coupons"}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>
                    {row.coupon_type === "LIMITED"
                      ? row.max_redemptions
                      : formatDate(row.expires_at)}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>
                    {row.coupon_type === "LIMITED" ? "Limited" : "Non Limited"}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>{formatDate(row.created)}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="h4">
                    {row.coupon.percent_off}%
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      setCurrClicked(row.doc_id);
                      mutation.mutate({
                        current_guild: guilds![currentGuild]?.id,
                        id: row.doc_id,
                      });
                    }}
                  >
                    {mutation.isLoading && currClicked === row.doc_id ? (
                      <CircularProgress style={{ height: 20, width: 20 }} />
                    ) : (
                      <DeleteIcon fontSize="small" />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CouponList;
