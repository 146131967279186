var months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const formatDate = (UNIX_timestamp: number) => {
  var a = new Date(UNIX_timestamp * 1000);

  const nth = function (d: number) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var time = `${month} ${date}${nth(date)}, ${year}`;
  return time;
};

export const daysInThisMonth = (dte: number) => {
  var now = new Date(dte);
  return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
};

export const getMonthAndDay = (dte: Date) => {
  return `${months[dte.getMonth()]} ${dte.getDate()}`;
};
