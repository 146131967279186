import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid, Typography } from "@material-ui/core";
import JSONInput from "react-json-editor-ajrm";
import { locale } from "./locale";
import { functions } from "../../../base";
import TextField from "@material-ui/core/TextField";
import { useAlert } from "../../../contexts/AlertContext";

export interface NewWebhookBatch {
  webhookList: { url: string; serverId: string; name: string }[];
}

interface Props {
  handleClose: () => void;
  handleClickOpen: () => void;
  open: boolean;
  newBatch: NewWebhookBatch | null;
}

const sendNewWebhookBatch = async (dataWebhook: any) => {
  const webhookBatch = functions.httpsCallable("createWebhookBatchLolFunction");
  const data = await webhookBatch(dataWebhook);
  return data.data;
};

const NewBatch: React.FC<Props> = ({
  handleClose,
  handleClickOpen,
  open,
  newBatch,
}) => {
  const [webhookBatchName, setWebhookBatchName] = useState("");
  const [JsonContent, setJsonContent] = useState("");
  const { setAlert } = useAlert();
  //   const handleSendBatch = () => {
  //     sendNewWebhookBatch =
  //   }
  const handleSendNewWebhook = async () => {
    if (webhookBatchName === "" || JsonContent === "") {
      return setAlert({
        type: "error",
        message: "please enter valid json or name",
      });
    }
    const newWebhook = await sendNewWebhookBatch({
      webhookName: webhookBatchName,
      webhookList: newBatch?.webhookList.map((v) => ({
        url: v.url,
        serverId: v.serverId,
      })),
      JsonContent,
    });
    console.log(newWebhook);
  };
  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Send Webhooks To
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={3}>
              <Grid item md={12}>
                <TextField
                  id="standard-basic"
                  label="Webhook Batch Name"
                  variant="outlined"
                  value={webhookBatchName}
                  onChange={(e) => setWebhookBatchName(e.target.value)}
                />
              </Grid>
              <Grid item md={12}>
                <Typography>
                  {newBatch
                    ? newBatch.webhookList.map((w) => w.name).join(", ")
                    : ""}
                </Typography>
              </Grid>
              <Grid item md={12}>
                <JSONInput
                  id="a_unique_id"
                  placeholder={{ test: "hi" }}
                  //   colors={darktheme}
                  locale={locale}
                  height="550px"
                  onChange={(e: any) => setJsonContent(e.json)}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button
            onClick={handleSendNewWebhook}
            color="primary"
            autoFocus
            // disabled
          >
            Send Webhooks
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewBatch;
