import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/database";
import "firebase/storage";

const config_prod = {
  apiKey: "AIzaSyBd4-PYqrL0ZOMxBAGdkg6Sz56paXx-dsA",
  authDomain: "torch-discord-bot.firebaseapp.com",
  databaseURL: "https://torch-discord-bot-default-rtdb.firebaseio.com",
  projectId: "torch-discord-bot",
  storageBucket: "torch-discord-bot.appspot.com",
  messagingSenderId: "189431525666",
  appId: "1:189431525666:web:804a50675e4dd9cde0357b",
  measurementId: "G-PNTCBC1WSW",
};

const app = firebase.initializeApp(config_prod);

var auth = app.auth();
var store = app.firestore();
var functions = app.functions();
var db = app.database();
var drive = app.storage();

if (window.location.hostname === "localhost") {
  store.useEmulator("localhost", 8080);
  auth.useEmulator("http://localhost:9099/");
  functions.useEmulator("localhost", 5001);
  db.useEmulator("localhost", 9000);
  drive.useEmulator("localhost", 9199);
}

export { app, auth, store, functions, db, drive };
