import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import { CouponAddNonLimitedValidation } from "../../../../utils/validSchemas";
import Grid from "@material-ui/core/Grid";
import { useGuild } from "../../../../contexts/GuildContext";
import { TextField, Select } from "formik-material-ui";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";
import {
  FilledDatePicker,
  PromoAddData,
  PromoAPIData,
  PromoData,
} from "../types";
import { useMutation } from "react-query";
import { functions } from "../../../../base";
import DateFnsUtils from "@date-io/date-fns";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { fieldToDatePicker } from "formik-material-ui-pickers";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Alert from "../../../../common/Alert";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";

function MuiDatePicker(props: FilledDatePicker) {
  return (
    <KeyboardDatePicker {...fieldToDatePicker(props)} inputVariant="filled" />
  );
}

async function setPromo(promo: PromoAddData) {
  // console.log("Sessing promos", promo);
  var addProm = functions.httpsCallable("addPromoCode");
  // console.log({ ...promo, plan_type: "Residential" });
  const type =
    promo.plan === "BASIC" ? "" + promo.type : promo.plan + promo.type;
  const new_promo: PromoAPIData = (await addProm({ ...promo, type })).data;
  // console.log(new_promo);
  const extracted = {
    id: new_promo.id,
    code: new_promo.code,
    active: new_promo.active,
    expires_at: new_promo.expires_at,
    times_redeemed: new_promo.times_redeemed,
    percent_off: new_promo.coupon.percent_off,
    type: new_promo.coupon.name,
    type_id: new_promo.coupon.id,
  };
  // console.log(extracted);
  return extracted;
}

const useStyles = makeStyles((theme) => ({
  couponText: {
    textShadow:
      theme.palette.type === "dark" ? "2px 2px 5px #000000" : undefined,
  },
}));

const tooldtip = (
  <React.Fragment>
    <Typography variant="h6">Limited</Typography>
    <Typography variant="subtitle1">
      Limited coupons have a limit on how many times they can be redeemed
    </Typography>
    <Typography variant="h6">Non Limited</Typography>
    <Typography variant="subtitle1">
      Non limited coupons can be redeemed indefinitly before the given expiry
      date
    </Typography>
  </React.Fragment>
);

export const ResiCoupon = () => {
  const classes = useStyles();

  const getPercentages = (plan: string) => {
    const num_coupons = Array.from(
      { length: plan === "BASIC" ? 10 : 5 },
      (_, i) => (i + 1) * 5
    );
    return num_coupons.map((value) => (
      <MenuItem value={`${value}OFF`}>{value}</MenuItem>
    ));
  };

  const { guilds, currentGuild } = useGuild();
  // @ts-ignore
  const mutation = useMutation<PromoData, Error>("set-promo", setPromo, {
    onError: (error) => {
      setError(error.message);
      // console.log(JSON.stringify(error));
    },
  });
  const [error, setError] = useState("");

  return (
    <div>
      <Alert isOpen={mutation!.isError} message={error} type={"error"} />
      <Alert
        isOpen={mutation!.isSuccess}
        message={"Successfully Added Coupon!"}
        type={"success"}
      />
      <Typography variant="h5" className={classes.couponText}>
        <b>Residential</b> Coupon
      </Typography>
      <br />
      <Formik
        initialValues={{
          type: "5OFF",
          code: "",
          plan: "BASIC",
          coupon_type: "NON_LIMITED",
          num_uses: 0,
          expires_at: new Date(),
        }}
        validationSchema={CouponAddNonLimitedValidation}
        onSubmit={async (values) => {
          await mutation.mutate({
            guildId: guilds![currentGuild].id,
            ...values,
            expires_at: Math.floor(values.expires_at.getTime() / 1000),
          });
        }}
      >
        {({ submitForm, errors, values }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item md={3}>
                <Field
                  component={TextField}
                  variant="filled"
                  fullWidth
                  name="code"
                  type="text"
                  label="Coupon code"
                />
              </Grid>
              <Grid item md={3}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">
                    Coupon Type
                  </InputLabel>
                  <Field
                    component={Select}
                    name="coupon_type"
                    inputProps={{
                      id: "type-simple",
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <Tooltip title={tooldtip} arrow>
                          <InfoIcon />
                        </Tooltip>
                      </InputAdornment>
                    }
                  >
                    <MenuItem value={"NON_LIMITED"}>Non Limited</MenuItem>
                    <MenuItem value={"LIMITED"}>Limited</MenuItem>
                  </Field>
                </FormControl>
              </Grid>
              <Grid item md={2}>
                {values.coupon_type === "LIMITED" ? (
                  <Field
                    component={TextField}
                    variant="filled"
                    fullWidth
                    name="num_uses"
                    type="number"
                    label="Number of uses"
                  />
                ) : (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Field
                      component={MuiDatePicker}
                      fullWidth
                      name="expires_at"
                      label="Coupon Expiry"
                    />
                  </MuiPickersUtilsProvider>
                )}
              </Grid>
              <Grid item md={2}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">
                    Plan Name
                  </InputLabel>
                  <Field
                    component={Select}
                    name="plan"
                    inputProps={{
                      id: "type-simple",
                    }}
                  >
                    <MenuItem value={"BASIC"}>Basic</MenuItem>
                    <MenuItem value={"PREM"}>Premium</MenuItem>
                    <MenuItem value={"ELITE"}>Elite</MenuItem>
                    <MenuItem value={"ALL"}>All Residential Plans</MenuItem>
                  </Field>
                </FormControl>
              </Grid>
              <Grid item md={2}>
                <FormControl fullWidth variant="filled">
                  <InputLabel htmlFor="age-simple">Percentage</InputLabel>
                  <Field
                    component={Select}
                    name="type"
                    inputProps={{
                      id: "type-simple",
                    }}
                  >
                    {getPercentages(values.plan)}
                  </Field>
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <DialogActions>
                  <Button
                    onClick={submitForm}
                    variant="outlined"
                    disabled={mutation.isLoading}
                  >
                    {mutation.isLoading ? (
                      <CircularProgress style={{ height: 20, width: 20 }} />
                    ) : (
                      "Create Coupon"
                    )}
                  </Button>
                </DialogActions>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResiCoupon;
