import React from "react";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { GuildInfo } from "../../contexts/GuildContext";
import { Grid, Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    fixedHeight: {
      height: 240,
    },
    large: {
      width: theme.spacing(25),
      height: theme.spacing(25),
      marginTop: theme.spacing(1),
    },
  })
);

interface Props {
  currentGuild: GuildInfo;
}
const Profile: React.FC<Props> = ({ currentGuild }) => {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <div>
      <Paper className={fixedHeightPaper}>
        <Grid
          item
          container
          direction="row"
          alignItems="flex-start"
          justify="flex-start"
          spacing={3}
        >
          <Grid item>
            <Avatar
              alt="Guild Avatar"
              src={currentGuild.display_avatar}
              className={classes.large}
            />
          </Grid>
          <Grid item>
            <Typography variant="h4">{`${currentGuild.server_name}`}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default Profile;
