import React from "react";
import { useTheme } from "@material-ui/core/styles";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import Title from "./Title";
import { daysInThisMonth } from "../../utils/formatDate";
import { IOrders } from "./types";

// Generate Sales Data
function createData(time: string, amount: number | undefined) {
  return { time, amount };
}

interface Trans {
  timestamp: number;
  paid: string;
}

function getMonthData(orders: IOrders[] | undefined, selectedDate: any) {
  let m_data: Trans[] = [];
  orders!.forEach((order) => {
    const data = order;
    // console.log(data);
    let date = new Date(Number(data.timestamp));
    if (
      selectedDate.getFullYear() === date.getFullYear() &&
      date.getMonth() === selectedDate.getMonth()
    )
      m_data.push(data);
  });
  return m_data;
}

function getDaySales(orders: Trans[], day: number, selectedDate: any) {
  let m_data = 0;
  orders.forEach((ord) => {
    let date_str = ord.timestamp;
    let conv_date;
    if (typeof date_str == "string") conv_date = parseInt(date_str) * 1000;
    else conv_date = date_str;
    let date = new Date(conv_date ? conv_date : 1612072562000);
    if (
      selectedDate.getFullYear() === date.getFullYear() &&
      date.getMonth() === selectedDate.getMonth() &&
      date.getDate() === day
    )
      m_data += parseFloat(ord.paid);
  });
  return m_data;
}

function genData(orders: IOrders[] | undefined, selectedDate: any) {
  let dataArr = [];
  // console.log(orders);
  const monthD = getMonthData(orders, selectedDate);
  // console.log(monthD);
  for (let i = 1; i <= daysInThisMonth(selectedDate.getTime()); i++) {
    // console.log(`Day ${i}, Sales:${getDaySales(monthD, i)}`);
    dataArr.push(
      createData(
        i.toString(),
        Math.round(getDaySales(monthD, i, selectedDate) * 1e2) / 1e2
      )
    );
    // console.log(dataArr);
  }
  return dataArr;
}

interface Props {
  ordersMonth: IOrders[] | undefined;
  selectedDate: any;
}

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Chart: React.FC<Props> = ({ ordersMonth, selectedDate }) => {
  const theme = useTheme();

  const data = [...genData(ordersMonth, selectedDate)];

  return (
    <React.Fragment>
      <Title>Monthly Sales Graph</Title>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis dataKey="time" stroke={theme.palette.text.secondary}>
            <Label
              angle={0}
              position="left"
              style={{ fill: theme.palette.text.primary }}
            >
              Date
            </Label>
          </XAxis>
          <YAxis stroke={theme.palette.text.secondary}>
            <Label
              angle={270}
              position="left"
              style={{ textAnchor: "middle", fill: theme.palette.text.primary }}
            >
              Sales ($)
            </Label>
          </YAxis>
          <Tooltip
            labelFormatter={(label, payload) =>
              `${label}, ${
                monthNames[selectedDate.getMonth()]
              }, ${selectedDate.getFullYear()}`
            }
            formatter={(value: string, name: string, props: any) => [
              `$${value}`,
              "Sales",
            ]}
            contentStyle={{ backgroundColor: theme.palette.background.paper }}
          />
          <Line
            dataKey="amount"
            stroke={theme.palette.primary.main}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
};

export default Chart;
