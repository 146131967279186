export interface ErrorCodes {
  "auth/invalid-email": string;
  "auth/user-disabled": string;
  "auth/user-not-found": string;
  "auth/wrong-password": string;
}
export const login_errors = {
  "auth/invalid-email": "Invalid email, please try again.",
  "auth/user-disabled":
    "Sorry. Your account has been disabled, please contact us through Discord to see why.",
  "auth/user-not-found":
    "We cannot find a email associated with this accout, You can always create an account by clicking the link below.",
  "auth/wrong-password":
    "This password does not match your email. Try again or click the Forgot Password link if you think you forgot it.",
};

export interface RegCodes {
  "auth/invalid-email": string;
  "auth/email-already-in-use": string;
}
export const register_errors = {
  "auth/invalid-email": "Your email seems to be wrong, please try again.",
  "auth/email-already-in-use":
    "This email is already in use. Please use the email you chose in the onboarding form",
  "auth/invalid_guild": "No guild found for this email",
};

export class RegisterError extends Error {
  code = "";
  constructor(code: string) {
    super("");
    this.code = code; // (2)
  }
}
