import React from "react";
import Typography from "@material-ui/core/Typography";
import Title from "../Title";

interface Props {
  totalUsers: number;
}

const TotalUsers: React.FC<Props> = ({ totalUsers }) => {
  return (
    <React.Fragment>
      <Title>Total Users</Title>
      <Typography component="p" variant="h4">
        {totalUsers}
      </Typography>
    </React.Fragment>
  );
};

export default TotalUsers;
