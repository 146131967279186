import React, { useContext, useState, useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { AlertProps } from "@material-ui/lab";
import MuiAlert from "@material-ui/lab/Alert";

import { makeStyles } from "@material-ui/core/styles";
const center = "center";
const left = "left";
const right = "right";
const bottom = "bottom";
const top = "top";
type VertWindow = typeof top | typeof bottom;
type HorWindow = typeof center | typeof left | typeof right;

const AlertBox: React.FC<AlertProps> = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
const ERROR = "error";
const WARNING = "warning";
const INFO = "info";
const SUCCESS = "success";

interface Context {
  setAlert: ({
    type,
    message,
  }: {
    type: MuiAlertSeverty;
    message: string;
  }) => void;
}

const PaymentContext = React.createContext({} as Context);

export function useAlert() {
  return useContext(PaymentContext);
}

type MuiAlertSeverty =
  | typeof ERROR
  | typeof WARNING
  | typeof INFO
  | typeof SUCCESS;

interface Props {
  children: React.ReactNode;
}

export const AlertProvider: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [buttonClick] = React.useState(false);
  const [type, setType] = React.useState<MuiAlertSeverty>("error");
  const anchor: { vertical: VertWindow; horizontal: HorWindow } = {
    vertical: "top",
    horizontal: "center",
  };
  const [message, setMessage] = React.useState("");

  function setAlert({
    type,
    message,
  }: {
    type: MuiAlertSeverty;
    message: string;
  }) {
    setOpen(true);
    setMessage(message);
    setType(type);
  }

  const handleClose = (event: React.SyntheticEvent<any, Event>) => {
    setOpen((prevState) => !prevState);
  };

  const value: Context = {
    setAlert,
  };

  return (
    <PaymentContext.Provider value={value}>
      <div className={classes.root}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={anchor}
        >
          <AlertBox onClose={handleClose} severity={type}>
            {message ? message : ""}
          </AlertBox>
        </Snackbar>
      </div>
      {children}
    </PaymentContext.Provider>
  );
};
