import React, { useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { AlertProps } from "@material-ui/lab";
import MuiAlert from "@material-ui/lab/Alert";

import { makeStyles } from "@material-ui/core/styles";
const center = "center";
const left = "left";
const right = "right";
const bottom = "bottom";
const top = "top";
type VertWindow = typeof top | typeof bottom;
type HorWindow = typeof center | typeof left | typeof right;

const AlertBox: React.FC<AlertProps> = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
const ERROR = "error";
const WARNING = "warning";
const INFO = "info";
const SUCCESS = "success";

type MuiAlertSeverty =
  | typeof ERROR
  | typeof WARNING
  | typeof INFO
  | typeof SUCCESS;

interface Props {
  isOpen: boolean;
  message: string | null;
  type: MuiAlertSeverty;
}

const Alert: React.FC<Props> = ({ isOpen, message, type }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [buttonClick] = React.useState(false);
  const anchor: { vertical: VertWindow; horizontal: HorWindow } = {
    vertical: "top",
    horizontal: "center",
  };

  const handleClose = (event: React.SyntheticEvent<any, Event>) => {
    setOpen((prevState) => !prevState);
  };

  useEffect(() => {
    !buttonClick ? setOpen(isOpen) : setOpen(false);
  }, [buttonClick, isOpen]);

  return isOpen ? (
    <div className={classes.root}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={anchor}
      >
        <AlertBox onClose={handleClose} severity={type}>
          {message ? message : ""}
        </AlertBox>
      </Snackbar>
    </div>
  ) : (
    <div />
  );
};

export default Alert;
