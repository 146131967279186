import React, { useContext, useState, useEffect } from "react";
// import Firebase from "firebase";
import { store } from "../base";
import { useAuth } from "./AuthContext";
import { useLocalStorage } from "../utils/useLocalStorage";

interface Context {
  colorTheme: string;
  setColorTheme: (color: string) => void;
}

const ColorThemeContext = React.createContext({} as Context);

export function useColorTheme() {
  return useContext(ColorThemeContext);
}

interface Props {
  children: React.ReactNode;
}

export const ColorThemeProvider: React.FC<Props> = ({ children }) => {
  const [colorTheme, setColorTheme] = useLocalStorage(
    "torchlabs-color-theme",
    "dark"
  );

  // function switchGuilds(index: number) {
  //   console.log(guildsLen, index);
  //   if (index < guildsLen!) {
  //     setCurrentGuild(index);
  //   } else {
  //     throw new Error("Guild index must be less than length");
  //   }
  // }

  const value: Context = {
    colorTheme,
    setColorTheme,
  };

  return (
    <ColorThemeContext.Provider value={value}>
      {children}
    </ColorThemeContext.Provider>
  );
};
