import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Title from "../Title";
import Grid from "@material-ui/core/Grid";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { IOrders } from "../types";
import { calcTransFees } from "@torchlabs/transactions";

const useStyles = makeStyles((theme) => ({
  depositContext: {
    flex: 1,
  },
  dataText: {
    textShadow:
      theme.palette.type === "dark" ? "2px 2px 5px #d43322" : undefined,
  },
}));

const title = (
  <React.Fragment>
    <Typography color="inherit">
      Your sales income this month after Stripe fees and cost of data
    </Typography>
    <List component="nav" aria-label="secondary mailbox folders">
      <ListItem>
        <ListItemText primary="Stripe Fees: 2.9% + 30 cents per transaction" />
      </ListItem>
      <ListItem>
        {/* Data cost: Basic Residential 1 GB = 2$ | Premium Residential 1 GB = 5$ | Elite Residential 1 GB = 11$ | DC/ISP = 1.5$ */}
        <ListItemText
          primary={
            <Typography>
              <Typography>Data cost:</Typography>
              <Typography>Basic Residential 1 GB = 2$</Typography>
              <Typography>Premium Residential 1 GB = 5$ </Typography>
              <Typography>Elite Residential 1 GB = 11$</Typography>
              <Typography>ISP = 1.5$</Typography>
            </Typography>
          }
        />
      </ListItem>
    </List>
  </React.Fragment>
);

function getMonthlySalesIncome(data: IOrders[] | undefined) {
  let total_trans_paid = 0;
  data!.forEach((trans) => {
    if (
      trans.plan_name === "Giveaway" ||
      (parseFloat(trans.paid) < 1 && parseFloat(trans.paid) !== 0)
    )
      return;
    total_trans_paid += calcTransFees(
      trans.plan_name,
      trans.paid,
      trans.payment_status,
      trans.price,
      trans.bandwidth ? trans.bandwidth : trans.proxy_count
    );
  });
  // console.log(total_trans_paid);
  return total_trans_paid;
}

interface Props {
  value: IOrders[] | undefined;
  selectedDate: any;
}

const DataUSDMonth: React.FC<Props> = ({ value, selectedDate }) => {
  const classes = useStyles();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <React.Fragment>
      <Grid container>
        <Grid item md={12}>
          <Title>Monthly Sales Income</Title>
        </Grid>
        <Grid item md={12}>
          <Typography component="p" variant="h4" className={classes.dataText}>
            $
            {getMonthlySalesIncome(value).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
          </Typography>
          <Typography color="textSecondary" className={classes.depositContext}>
            {`on ${
              monthNames[selectedDate.getMonth()]
            }, ${selectedDate.getFullYear()}`}
          </Typography>
        </Grid>
        <Grid container item md={12} justify="flex-end">
          <Tooltip title={title} arrow>
            <InfoIcon />
          </Tooltip>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default DataUSDMonth;
