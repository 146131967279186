import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Title from "./Title";
import { IOrders } from "./types";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Button } from "@material-ui/core";
import AllTransactions from "./Data/AllTransactions";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  table: {
    backgroundColor: theme.palette.background.default,
  },
  bord: {
    borderColor: theme.palette.background.default,
  },
  tableContainer: {
    boxShadow: "none",
  },
}));

// Generate Order Data
function createData(
  id: number,
  name: string,
  email: string,
  date: string,
  type: string,
  bandwidth: string,
  amount: { op: string; amt: string }
) {
  return { id, date, name, email, type, bandwidth, amount };
}

interface Props {
  orderData: IOrders[] | undefined;
}

interface Options {
  weekday: "long" | "short" | "narrow" | undefined;
  month: "long" | "short" | "narrow" | "numeric" | "2-digit" | undefined;
  day: "numeric" | "2-digit" | undefined;
}
const options: Options = {
  weekday: "long",
  month: "long",
  day: "numeric",
};

function genData(orders: IOrders[] | undefined) {
  let data: {
    id: number;
    date: number;
    name: string;
    email: string;
    type: string;
    bandwidth: string;
    amount: { op: string; amt: string };
  }[] = [];
  let sorted_data: {
    id: number;
    date: string;
    name: string;
    email: string;
    type: string;
    bandwidth: string;
    amount: { op: string; amt: string };
  }[] = [];

  orders?.forEach((ord, idx) => {
    console.log(ord.tag);
    let ord_data = ord;
    let conv_date;
    if (typeof ord_data.timestamp == "string")
      conv_date = parseInt(ord_data.timestamp) * 1000;
    else conv_date = ord_data.timestamp;
    data.push({
      id: idx,
      name: ord_data.tag ? ord_data.tag : "N/A",
      email: ord_data.email,
      date: conv_date ? conv_date : 1612072562000,
      bandwidth: ord_data.bandwidth
        ? `${ord_data.bandwidth} GB`
        : ord_data.description,
      type: ord_data.plan_name,
      amount:
        ord.payment_status === "credit"
          ? { op: "-", amt: ord_data.price }
          : { op: "", amt: ord_data.paid },
    });
  });
  const sorted = data.sort(function (a, b) {
    return b.date - a.date;
  });
  let loop_iter = sorted.length >= 10 ? 10 : sorted.length;
  for (let i = 0; i < loop_iter; i++) {
    // @ts-ignore
    // console.log(parseInt(sorted[i].date));
    // @ts-ignore
    let date = new Date(sorted[i].date);
    sorted_data.push(
      createData(
        sorted[i].id,
        sorted[i].name,
        sorted[i].email,
        date.toLocaleDateString("en-US", options),
        sorted[i].type,
        sorted[i].bandwidth,
        sorted[i].amount
      )
    );
  }
  return sorted_data;
}

const Orders: React.FC<Props> = ({ orderData }) => {
  const rows = [...genData(orderData)];
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <section
        id="main"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Title>Recent Orders</Title>
        <Button variant="outlined" onClick={handleClickOpen}>
          All Orders
        </Button>
      </section>
      <TableContainer
        component={Paper}
        elevation={0}
        className={classes.tableContainer}
      >
        <Table size="small" className={classes.bord}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Proxy Type</TableCell>
              <TableCell>Bandwidth/Number of Proxies</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.bandwidth}</TableCell>
                <TableCell>{row.amount.op + " $" + row.amount.amt}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AllTransactions
        handleClose={handleClose}
        open={isOpen}
        orderData={orderData}
      />
    </React.Fragment>
  );
};
export default Orders;
