import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { countries, stripe_countries } from "./country_list";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useMutation } from "react-query";
import { functions } from "../../../base";
import Alert from "../../../common/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useGuild } from "../../../contexts/GuildContext";
import { useLocation } from "react-router-dom";
import { usePayment } from "../../../contexts/PaymentContext";
import Loader from "../../../common/Loader";
import NoAccount from "./account_states/NoAccount";
import AccountComplete from "./account_states/AccountComplete";
import AccountNotComplete from "./account_states/AccountNotComplete";
import { drive } from "../../../base";
import Invoices from "./Invoices";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "transparent",
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  paymentText: {
    textShadow:
      theme.palette.type === "dark" ? "2px 2px 5px #000000" : undefined,
  },
  paper2: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  loader: {
    position: "fixed",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    background: "rgba(51,51,51,0.7)",
  },
  progress: {
    top: "50%",
    left: "50%",
  },
}));

function getComponentAccountStatus(
  account: boolean | null,
  setup_completed: boolean | null
) {
  if (!account && !setup_completed) return <NoAccount />;
  if (account && !setup_completed) return <AccountNotComplete />;
  if (account && setup_completed) return <AccountComplete />;
  return <h1>Hi</h1>;
}

interface PayoutStatus {
  account: boolean | null;
  setup_completed: boolean | null;
}

const Payments = () => {
  const classes = useStyles();
  const { guilds, currentGuild } = useGuild();
  const { accountStatus, loading, error, setError } = usePayment();

  if (loading || accountStatus.account == null) return <Loader />;

  return (
    <div>
      <Paper className={classes.paper} elevation={0}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Paper className={classes.paper2}>
              <Typography variant="h5" className={classes.paymentText}>
                Set Up Payments
              </Typography>
              <br />
              <Typography>
                Welcome to payments! To get started please select the country in
                which your bank resides.
              </Typography>
              <br />
              <Grid container>
                {getComponentAccountStatus(
                  accountStatus.account,
                  accountStatus.setup_completed
                )}
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={12}>
            <Paper className={classes.paper2}>
              <Invoices />
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default Payments;
