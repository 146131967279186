import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { countries, stripe_countries } from "../country_list";
import { makeStyles } from "@material-ui/core/styles";
import { usePayment } from "../../../../contexts/PaymentContext";
import { useGuild } from "../../../../contexts/GuildContext";
import Button from "@material-ui/core/Button";
import { useMutation } from "react-query";
import CircularProgress from "@material-ui/core/CircularProgress";
import { functions } from "../../../../base";
import NoStripeBankDetails from "./NoStripeBankDetails";
import { useAlert } from "../../../../contexts/AlertContext";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  loader: {
    position: "fixed",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    background: "rgba(51,51,51,0.7)",
  },
  progress: {
    top: "50%",
    left: "50%",
  },
}));

function isStripeSupported(country_code: string | undefined) {
  console.log(country_code);
  for (let c of stripe_countries) {
    console.log(c);
    if (c.code === country_code) return true;
  }
  return false;
}

interface OnboardData {
  guildId: string;
  country: string | undefined;
}

async function onboardUser(data: OnboardData) {
  //   if (!data.country) throw new Error("country cannot be empty");
  var onboardStripeUser = functions.httpsCallable("onboardStripeUser");
  const link_href: { url: string } = (await onboardStripeUser(data)).data;
  console.log(link_href);
  // console.log(extracted);
  return link_href;
}

function countryToFlag(isoCode: string) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const NoAccount = () => {
  const classes = useStyles();
  const { setCountry, country, setNoStripeOpen } = usePayment();
  const { guilds, currentGuild } = useGuild();
  const { setAlert } = useAlert();

  // @ts-ignore
  const mutation = useMutation("onboard-stripe", onboardUser, {
    onError: (error: Error) => {
      console.log(error.message);
      setAlert({
        type: "error",
        message: error.message,
      });
    },
    onSuccess: (data) => {
      setAlert({
        type: "success",
        message: "Redirecting to stripe...",
      });
      window.location.replace(data.url);
    },
  });

  return (
    <div>
      <NoStripeBankDetails />
      <Autocomplete
        id="country-select-demo"
        style={{ width: 300 }}
        options={countries}
        classes={{
          option: classes.option,
        }}
        autoHighlight
        getOptionLabel={(option) => option.label}
        renderOption={(option) => (
          <React.Fragment>
            <span>{countryToFlag(option.code)}</span>
            {option.label} ({option.code})
          </React.Fragment>
        )}
        onChange={(event, newValue) => {
          setCountry(newValue?.code);
          console.log(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Choose a country"
            variant="filled"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
      <Button
        onClick={async () => {
          if (!country) {
            return setAlert({
              type: "error",
              message: "Please select a country",
            });
          }
          if (!isStripeSupported(country)) {
            return setNoStripeOpen(true);
          }
          return await mutation.mutate({
            guildId: guilds![currentGuild].id,
            country,
          });
        }}
        disabled={mutation.isLoading}
        // disabled={country !== "US" ? true : false}
      >
        {mutation.isLoading ? (
          <CircularProgress style={{ height: 20, width: 20 }} />
        ) : (
          "Continue"
        )}
      </Button>
    </div>
  );
};

export default NoAccount;
