import React, { useContext, useState, useEffect } from "react";
// import Firebase from "firebase";
import { store } from "../base";
import { useAuth } from "./AuthContext";
import { useLocation } from "react-router-dom";
import { functions } from "../base";

const STRIPE = "stripe";
const BANK = "bank";

interface PayoutStatus {
  account: boolean | null;
  setup_completed: boolean | null;
  setup_type: typeof STRIPE | typeof BANK | null;
}

interface Context {
  accountStatus: PayoutStatus;
  setAccountStatus: React.Dispatch<React.SetStateAction<PayoutStatus>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  country: string | undefined;
  setCountry: React.Dispatch<React.SetStateAction<string | undefined>>;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
  noStripeOpen: boolean;
  setNoStripeOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface GuildInfo {
  admin: string;
  date_joined: number;
  email: string;
  proxy_url: string;
  total_transactions: number;
  total_users: number;
  id: string;
  server_name: string;
  profit_percent: string;
  display_avatar: string;
}

const PaymentContext = React.createContext({} as Context);

export function usePayment() {
  return useContext(PaymentContext);
}

interface Props {
  children: React.ReactNode;
}

export const PaymentProvider: React.FC<Props> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  let loc = useLocation();
  const [accountStatus, setAccountStatus] = useState<PayoutStatus>({
    account: null,
    setup_completed: null,
    setup_type: null,
  });
  const [country, setCountry] = useState<string | undefined>();
  const [error, setError] = useState("");
  const [noStripeOpen, setNoStripeOpen] = useState(false);

  useEffect(() => {
    let isCancelled = false;
    setLoading(true);
    if (loc.hash === "#/payments/refresh/") {
      var refresh = functions.httpsCallable("refreshStripeUser");
      refresh().then((val) => {
        const { redirect } = val.data;
        // console.log(window.location.pathname);
        window.location.assign(redirect);
        setLoading(false);
      });
    } else {
      var account_info = functions.httpsCallable("checkStripeAccountStatus");
      account_info()
        .then((val) => {
          const accout_stat = val.data as PayoutStatus;
          console.log(val);
          if (!isCancelled) {
            setAccountStatus(accout_stat);
            setLoading(false);
          }
          // console.log(accout_stat);
        })
        .catch((err) => console.log(err));
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  // function switchGuilds(index: number) {
  //   console.log(guildsLen, index);
  //   if (index < guildsLen!) {
  //     setCurrentGuild(index);
  //   } else {
  //     throw new Error("Guild index must be less than length");
  //   }
  // }

  const value: Context = {
    accountStatus,
    setAccountStatus,
    loading,
    setLoading,
    country,
    setCountry,
    error,
    setError,
    noStripeOpen,
    setNoStripeOpen,
  };

  return (
    <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>
  );
};
