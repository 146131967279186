import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Title from "../Title";
import { IOrders } from "../types";

const useStyles = makeStyles((theme) => ({
  depositContext: {
    flex: 1,
  },
  dataText: {
    textShadow:
      theme.palette.type === "dark" ? "2px 2px 5px #d43322" : undefined,
  },
}));

function getMonthGbSold(data: IOrders[] | undefined) {
  let total_gb = 0;
  let total_proxies = 0;
  data!.forEach((order) => {
    // console.log(order);
    if (parseFloat(order.paid) > 1 && order.plan_name !== "Giveaway") {
      if (order.plan_name === "Premium Datacenter") {
        total_proxies += parseFloat(order.proxy_count);
      } else {
        total_gb += parseFloat(order.bandwidth);
      }
    }
  });
  // console.log({ total_gb, total_proxies });
  return { total_gb, total_proxies };
}

interface Props {
  data: IOrders[] | undefined;
  selectedDate: any;
}
const MonthTotalGb: React.FC<Props> = ({ data, selectedDate }) => {
  const classes = useStyles();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <React.Fragment>
      <Title>Monthly Data Sold</Title>
      <Typography component="p" variant="h5" className={classes.dataText}>
        {`${getMonthGbSold(data).total_gb}GB`},{" "}
        {`${getMonthGbSold(data).total_proxies} ISPs`}
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        {`on ${
          monthNames[selectedDate.getMonth()]
        }, ${selectedDate.getFullYear()}`}
      </Typography>
    </React.Fragment>
  );
};

export default MonthTotalGb;
