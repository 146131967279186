import React, { useContext, useState, useEffect } from "react";
// import Firebase from "firebase";
import { store } from "../base";
import { useAuth } from "./AuthContext";
import { useLocalStorage } from "../utils/useLocalStorage";

interface Context {
  currentGuild: number;
  setCurrentGuild: React.Dispatch<React.SetStateAction<number>>;
  guilds: GuildInfo[] | null;
  loading: boolean;
}

export interface GuildInfo {
  admin: string;
  date_joined: number;
  email: string;
  proxy_url: string;
  total_transactions: number;
  total_users: number;
  id: string;
  server_name: string;
  profit_percent: string;
  display_avatar: string;
}

const GuildContext = React.createContext({} as Context);

export function useGuild() {
  return useContext(GuildContext);
}

async function fetchUserGuild(email: string) {
  // console.log("fetching...");

  const guildRefQuery = store.collection("channels");
  const userGuild = guildRefQuery.where("email", "==", email);
  const data = await userGuild.get();
  let guilds: GuildInfo[] = [];
  data.docs.forEach((doc) => {
    guilds.push({ ...(doc.data() as GuildInfo), id: doc.id });
  });
  // console.log("fetched...");
  return guilds;
}

interface Props {
  children: React.ReactNode;
}

export const GuildProvider: React.FC<Props> = ({ children }) => {
  const { currentUser } = useAuth();
  const [guilds, setGuilds] = useState<null | GuildInfo[]>([]);
  const [, setGuildsLen] = useState<null | number>(null);
  const [loading, setLoading] = useState(false);
  const [currentGuild, setCurrentGuild] = useLocalStorage(
    "torchlabs-current-guild",
    0
  );

  useEffect(() => {
    setLoading(true);
    if (currentUser?.email) {
      // console.log(currentUser?.email);
      fetchUserGuild(currentUser.email).then((guilds) => {
        setGuilds(guilds);
        // console.log(guilds);
        setGuildsLen(guilds.length);
        setLoading(false);
      });
    }
  }, [currentUser, currentGuild]);

  // function switchGuilds(index: number) {
  //   console.log(guildsLen, index);
  //   if (index < guildsLen!) {
  //     setCurrentGuild(index);
  //   } else {
  //     throw new Error("Guild index must be less than length");
  //   }
  // }

  const value: Context = {
    currentGuild,
    setCurrentGuild,
    guilds,
    loading,
  };

  return (
    <GuildContext.Provider value={value}>
      {!loading && children}
    </GuildContext.Provider>
  );
};
