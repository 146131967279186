import React from "react";
import { functions } from "../../../../base";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { usePayment } from "../../../../contexts/PaymentContext";
import { useAlert } from "../../../../contexts/AlertContext";
import { useHistory } from "react-router-dom";

const AccountNotComplete = () => {
  const { loading, setLoading } = usePayment();
  const { setAlert } = useAlert();
  const history = useHistory();

  return (
    <div>
      <Button
        onClick={() => {
          setLoading(true);
          setAlert({
            type: "success",
            message: "Redirecting to stripe...",
          });
          var refresh = functions.httpsCallable("refreshStripeUser");
          refresh().then((val) => {
            const { redirect } = val.data;
            window.location.assign(redirect);
            setLoading(false);
          });
        }}
        disabled={loading}
      >
        {loading ? (
          <CircularProgress style={{ height: 20, width: 20 }} />
        ) : (
          "Continue setting up your account"
        )}
      </Button>
    </div>
  );
};

export default AccountNotComplete;
