import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { usePayment } from "../../../../contexts/PaymentContext";
import Grid from "@material-ui/core/Grid";
import { TextField, Select } from "formik-material-ui";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Formik, Field, Form } from "formik";
import { functions } from "../../../../base";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAlert } from "../../../../contexts/AlertContext";
import { BankDetailsValidation } from "../../../../utils/validSchemas";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
import "./torn.css";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "@material-ui/core";

type MUIColor =
  | "error"
  | "inherit"
  | "initial"
  | "primary"
  | "secondary"
  | "textPrimary"
  | "textSecondary"
  | undefined;

const NoStripeBankDetails = () => {
  const { noStripeOpen, setNoStripeOpen, setAccountStatus } = usePayment();
  const { setAlert } = useAlert();
  const [loading, setLoading] = useState(false);
  const [numberClickText, setNumberClickText] = useState("none");

  function getNumberClickState(state: string) {
    if (state == "none")
      return <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>;
    if (state == "routing")
      return <span style={{ marginLeft: 15 }}>Routing number</span>;
    if (state == "account")
      return <span style={{ marginLeft: 110 }}>Account number</span>;
  }

  function getTextColorRouting(state: string) {
    if (state == "routing") return "#10163a";
    return "#ffffff";
  }

  function getTextColorAcct(state: string) {
    if (state == "account") return "#10163a";
    return "#ffffff";
  }

  const handleClose = () => {
    setNoStripeOpen(false);
  };

  // return <Typography>Support for this country comming soon</Typography>;
  return (
    <div>
      <Dialog
        open={noStripeOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Support for this country comming soon"}
        </DialogTitle>
        <DialogContent>
          <Typography>
            For now if you have not already, please fill out{" "}
            <Link href="https://airtable.com/shrL4pXsSF6RaDHCz">this form</Link>{" "}
            with your payment info
          </Typography>
        </DialogContent>
        {/* <Formik
          initialValues={{
            account_type: "Buisness Checking",
            legal_name: "",
            routing_number: "",
            account_number: "",
          }}
          validationSchema={BankDetailsValidation}
          onSubmit={(values) => {
            console.log(values);
            setLoading(true);
            var createBankInfoRecord = functions.httpsCallable(
              "createBankInfoRecord"
            );
            createBankInfoRecord(values)
              .then((val) => {
                console.log(val.data);
                setAccountStatus(val.data);
                setAlert({
                  type: "success",
                  message: "Successfully saved bank details",
                });
                setLoading(false);
                setNoStripeOpen(false);
              })
              .catch((err: Error) => {
                setAlert({
                  type: "error",
                  message: err.message,
                });
                setLoading(false);
              });
          }}
        >
          {({ submitForm, errors, values }) => (
            <Form>
              <DialogContent>
                <Grid container spacing={3}>
                  <Grid item md={12}>
                    <Field
                      component={TextField}
                      variant="filled"
                      fullWidth
                      name="legal_name"
                      type="text"
                      label="Legal Name"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <Paper
                      className="box"
                      elevation={5}
                      style={{
                        backgroundColor: "#6777d5",
                        borderRadius: 0,
                        padding: 10,
                        paddingTop: 20,
                      }}
                    >
                      <Divider />
                      <br />

                      <Typography
                        style={{
                          fontFamily: "'Exo 2', sans-serif",
                        }}
                      >
                        ⑆{" "}
                        <Typography
                          display="inline"
                          style={{
                            fontFamily: "'Exo 2', sans-serif",
                            color: getTextColorRouting(numberClickText),
                          }}
                        >
                          <b>110000000</b>
                        </Typography>{" "}
                        ⑆{" "}
                        <Typography
                          display="inline"
                          style={{
                            fontFamily: "'Exo 2', sans-serif",
                            color: getTextColorAcct(numberClickText),
                          }}
                        >
                          <b>000123456789</b>
                        </Typography>{" "}
                        ⑈ <b>1234</b>
                      </Typography>
                      <Typography variant="subtitle2">
                        {getNumberClickState(numberClickText)}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item md={12}>
                    <Field
                      component={TextField}
                      variant="filled"
                      fullWidth
                      name="routing_number"
                      type="text"
                      label="Routing Number"
                      onFocus={() => setNumberClickText("routing")}
                      onBlur={() => setNumberClickText("none")}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <Field
                      component={TextField}
                      variant="filled"
                      fullWidth
                      name="account_number"
                      type="text"
                      label="Account Number"
                      onFocus={() => setNumberClickText("account")}
                      onBlur={() => setNumberClickText("none")}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel htmlFor="age-simple">
                        {"Account Type"}
                      </InputLabel>
                      <Field
                        component={Select}
                        name={"account_type"}
                        inputProps={{
                          id: "type-simple",
                        }}
                      >
                        <MenuItem value={"Buisness Checking"}>
                          Buisness Checking
                        </MenuItem>
                        <MenuItem value={"Buisness Savings"}>
                          Buisness Savings
                        </MenuItem>
                        <MenuItem value={"Personal Checking"}>
                          Personal Checking
                        </MenuItem>
                        <MenuItem value={"Personal Savings"}>
                          Personal Savings
                        </MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={submitForm} color="primary" disabled={loading}>
                  {loading ? (
                    <CircularProgress style={{ height: 20, width: 20 }} />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik> */}
      </Dialog>
    </div>
  );
};

export default NoStripeBankDetails;
