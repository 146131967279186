import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import { TextField } from "formik-material-ui";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Formik, Field, Form } from "formik";
import Alert from "@material-ui/lab/Alert";
import { login_errors, ErrorCodes } from "../../utils/errorCodes";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://torchlabs.xyz/">
        TorchLabs
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login: React.FC = () => {
  const classes = useStyles();
  const { login } = useAuth();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleLogin = async (email: string, password: string) => {
    try {
      setLoading(true);
      await login(email, password);
      history.push("/dashboard");
    } catch (error) {
      // console.log(error);
      setLoading(false);
      const code: keyof ErrorCodes = error.code;
      setError(login_errors[code]);
    }
    setLoading(false);
  };

  return (
    <section className="login-bg">
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>TorchLabs &bull; Login</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
      </div>
      <Container
        component="main"
        maxWidth="xs"
        style={{
          borderRadius: 15,
          padding: "0px 10px 10px 10px",
          marginTop: 0,
        }}
      >
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              onSubmit={(data) => handleLogin(data.email, data.password)}
            >
              {() => (
                <Form className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <Field
                        component={TextField}
                        variant="outlined"
                        fullWidth
                        name="email"
                        type="text"
                        label="Email"
                        autofocus
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Field
                        component={TextField}
                        variant="outlined"
                        fullWidth
                        name="password"
                        type="password"
                        label="Password"
                        autofocus
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {error === "" ? (
                        ""
                      ) : (
                        <Alert icon={false} severity="error">
                          {error}
                        </Alert>
                      )}
                    </Grid>
                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    <Grid item md={12} sm={6}>
                      <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    className={classes.submit}
                  >
                    {loading ? (
                      <CircularProgress
                        style={{ height: 20, width: 20, color: "white" }}
                      />
                    ) : (
                      "Login"
                    )}
                  </Button>
                </Form>
              )}
            </Formik>
            <Grid container>
              {/* <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid> */}
              <Grid item>
                <Link href="/register" variant="body1">
                  Don't have an account? <b>Sign Up</b>
                </Link>
              </Grid>
            </Grid>
          </div>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Container>
      </Container>
    </section>
  );
};

export default Login;
